import React from 'react';
import "./Header.scss";

function Header() {

    return (
        <div id="HeaderNav" className={"bg-black d-flex justify-content-center align-items-center"}>
            <a href={"#"}>
                <img src="https://assets.uxnetwork.eu/logos/white.png" alt="logo"/>
            </a>
        </div>
    );
}

export default Header;