import React from 'react';
import { useTranslation } from 'react-i18next';
import "./Index.scss";

function Index() {
    const { t } = useTranslation();

    return (
        <div id="Index">
            <div className="container vh-100 d-flex flex-row justify-content-center align-items-center text-center">
                <h1>UX-NETWORK</h1>
            </div>
        </div>
    );
}

export default Index;
