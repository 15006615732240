import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(HttpApi) // Lädt Übersetzungen über http (hier kannst du deine JSON-Dateien ablegen)
    .use(LanguageDetector) // Erkennt automatisch die Sprache des Benutzers
    .use(initReactI18next) // bindet i18next an React an
    .init({
        supportedLngs: ['en', 'de'], // Unterstützte Sprachen
        fallbackLng: "en", // Standard falls keine Übersetzung gefunden wurde
        detection: {
            order: ['path', 'cookie', 'htmlTag'], // Reihenfolge, wie die Sprache erkannt wird
            caches: ['cookie'], // Speichert die Benutzersprache in einem Cookie
        },
        backend: {
            loadPath: '/assets/locales/snippets-{{lng}}.json', // Pfad zu den Sprachdateien
        },
        react: {
            useSuspense: false // Verwendet Suspense nicht
        }
    });

export default i18n;
